
.web_login {
    width: 100%;
    /* padding: 80px 0; */
    position: relative;
    background-color: #2b76b9;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%);
}

.web_login h1 {
    font-size: 30px;
    color: #000;
    text-align: center;
}


.login_form {
    margin: 0 auto;
    width: 450px;
    background-color: rgb(255 255 255 / 18%);
    /* position: absolute; */
    /* transform: translate(-50%,-50%); */
    /* top: 50%; */
    /* left: 50%; */
    border-radius: 10px;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(3px);
    border: 1px solid rgb(0 0 0 / 10%);
    box-shadow: 0 0 26px 0px rgb(8 7 16 / 26%);
    padding: 40px 25px;
}

@media (max-width: 480px) {
    .login_form {
        width: 100%;
        padding: 10px 0;
    }
}