
/* ============================================ backend ========================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
::selection {
    color: white;
    background: #002173;
  }
body{
    background-color: #f4f6fc!important; 
}

span,p,a {
    font-family: 'Rubik', sans-serif!important;
}

.aside {
    background-color: #002173!important;
    transition: all .3s linear;
}
.brand-dark .brand {
    background-color: #002173!important;
}
.aside-menu {
    background-color: #002173!important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #001548!important;
}
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
    background-color: #001548!important;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #ededed!important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #3699FF!important;
}
.brand-dark .brand .btn .svg-icon svg g [fill] {
    fill: #ededed!important;
}

.header-mobile, .brand-dark .header-mobile {
    background-color: #ffffff!important;
}
.custom_datatable_head{
    font-family: 'Rubik', sans-serif;
    background: #f3f5f9
}
.custom_datatable td, .custom_datatable td span{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color:#2e384d;
}
.custom_datatable.table-striped tbody tr:nth-of-type(odd) {
    background-color: #eff3f6;
}
.custom_datatable.table-hover tbody tr:hover {
    background-color: #eff3f6;
    transform: scale(1.0055);
    font-weight: 500;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
}
.custom_datatable td.total{
    font-weight: 500;
}
.custom_datatable td span.text-success{
    color:#1bc5bd!important;
}
.selected_stones, .selected_stones span{
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #2e384d;
}
.selected_diamonds_status{
    font-family: 'Rubik', sans-serif;
    font-size: 11px;
    font-weight: normal;
    color: #8798ad;
}
.page_size{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    color: #2e384d;
}
.custom_hr{
    color:#c3c3c3;
}
.table_page_length{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500!important;
    font-style: italic;
    color: #8798ad!important;
}

.aside-menu .menu-nav .menu-item > .menu-heading, .aside-menu .menu-nav .menu-item > .menu-link {
    transition: all .7s linear;
    white-space: nowrap;
}
.brand-logo{
    transition: all 1s linear;
}
.small_title{
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.3px;
    color: #b0bac9;
}
.medium_title{
    font-family: Rubik;
    font-size: 18px;
    color: #2e384d;
}
.dasboardSearch{
    height: 480px;
}
.dasboardSearch .nav .nav-link {
    color: #2e5bff;
    font-size: 14px;
    font-family: Rubik;
    border: none;
    border-bottom: 3px solid transparent;
}
.dasboardSearch .nav .show > .nav-link, .dasboardSearch .nav .nav-link:hover:not(.disabled), .dasboardSearch .nav .nav-link.active {
    color: #2e384d;
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.14;
}
.dasboardSearch .nav-tabs .nav-link.active, .dasboardSearch .nav-tabs .nav-item.show .nav-link {
    border: none;
    border-bottom: 3px solid #002173;
}
.dasboardSearch .nav .show > .nav-link, .dasboardSearch .nav .nav-link:hover:not(.disabled), .dasboardSearch .nav .nav-link.active {
    border: none;
    padding: 0.5rem 1.5rem;
    border-bottom: 3px solid #002173;
}
.diamondBox{
    border: solid 0.5px #dfecff;
    border-radius: 8px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.diamondBox:hover{
    background-color: #dfecff20;
    box-shadow: 0 .125rem .25rem rgba(0,0,21,.075);
}
.diamondBox p{
    color: #2e384d;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 0px;
}
.wbg {
    background-color: #7a9cff40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.wbg .white_dia_icon {
    background: url(../images/wishlist_icon.png) 0px 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.fbg {
    background-color: #bb314c40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.fbg .fancy_dia_icon {
    background: url(../images/wishlist_icon.png) -30px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.pbg {
    background-color: #7ccdf240;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.pbg .parcel_dia_icon {
    background: url(../images/wishlist_icon.png) 30px 0px;
    padding-top: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lbg {
    background-color: #00c89740;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lbg .lab_dia_icon {
    background: url(../images/wishlist_icon.png) 60px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lfbg {
    background-color: #f0b33f40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lfbg .lab_fancy_dia_one {
    background: url(../images/wishlist_icon.png) 90px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lpbg {
    background-color: #b3f27c40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lpbg .lab_parcel_dia_one {
    background: url(../images/wishlist_icon.png) 30px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.tracklistdata{
    margin-top: 10px;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 5px;
}
.W_diamond {
    border-radius: 50%;
    background-color: #002173;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.F_diamond {
    border-radius: 50%;
    background-color: #bb314c;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.L_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.LF_diamond {
    border-radius: 50%;
    background-color: #f0b33f;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.P_diamond {
    border-radius: 50%;
    background-color: #7CCDF2;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.recentlyViewedDash{
    height: 630px;
}
.Instock_label{
    color: #1bc5bd!important;
    border-radius: 5px!important;
    background-color: #d4fffd;
    font-family: Rubik!important;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.Outofstock_label{
    color: #F64E60!important;
    background-color: #FFE2E5!important;
    border-radius: 5px!important;
    font-family: Rubik;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.flagIco{
    height: 16px!important;
    border-radius: 5px;
}
.savedSearchTile{
   height: 412px;
}

.salesExecutiveTile{
   height: 194px;
}
.tooltip-inner {
    text-transform: capitalize;
}
.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check > .selectBox {
    cursor: pointer;
    padding: 8px;
    margin: 1.5px;
    text-align: center;
    background-color: #00c89710;
    border: 1px solid #00c89715;
    overflow: hidden;
}
.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}
.search_btn_check .shapeBox.selectBox{
    width: 85px;
    height: 70px;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #00c897;
}
.selectBox:hover {
    border:1px solid #00c897;
}
.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}
.search_btn_check > .selectBox svg{
    height: 36px;
    width: auto;
}
.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #00c897!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}
.accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
    right: 19px;
    position: absolute;
}
.search_accordion .card {
    border: none!important;
}
.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}
.expandedDetails{
    height: 455px;
    min-width: 1700px;
    background-color: #fbfbfb!important;
}
/* .custom_datatable{
    min-width: 1850px;
} */
.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 50px;
    width: 50px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 48px;
    margin: 0 auto;
}
.img_box{
    margin-left:10px ;
    width: 370px;
    height: 328px;
    background-color: #EBEDF3;
    text-align: center;
}
.img_box img{
    height: 328px;
    margin: 0 auto;
}

.product_detail .img_box{
    margin-left:10px ;
    width: 470px;
    height: 400px;
    background-color: #EBEDF3;
    text-align: center;
}
.product_detail .img_box img{
    height: 400px;
    margin: 0 auto;
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.minHeight{
    min-height: 49px!important;
}

.gridData.card{
    border-radius: 0;
}
.gridData.card:hover {
    z-index: 0;
    box-shadow: 0px 8px 8px -10px rgb(0 0 0 / 68%);
    transition: all .3s ease-in-out;
    transform: scale(1.08);
    border-radius: 8px;
}

.gridData.card .img-fluid{
    height: 220px;
}
.table-hover tbody tr.expanded_tr:hover{
    background-color: #fff!important;
}
.table-hover tbody tr.expanded_tr:hover th{
    background-color: #f3f5f9;
}
.sh_expand{
    margin:15px;
}
.sh_expand:hover{
    background-color: #fff;
}

.sh_expand:hover th{
    background-color: #f3f5f9;
}

.table-hover td .sh_expand tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

.table-hover tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}

.session-modal{
    display: none!important;
}

.session-modal.show{
    display: block!important;
}

.session-modal-button{
    display: none!important;
}

.session-modal-button.show{
    display: block!important;
}
.modal .selectSess.modal-header .close span {
    display: block;
}